import React from "react";

import {Layout, Menu} from "antd";

import {Menu as Header} from "../Menu";
import {USER_MANAGEMENT_LINK} from "../properties";

export const openLink = href => {
	window.open(href, "_self");
};

export class MenuLayout extends React.Component {
	render() {
		const selected = this.props.location.pathname.split("/")[2];

		return (
			<Layout style={{minHeight: "100vh"}}>
				<Layout>
					<Header />
					<Layout>
						<Layout.Sider width={"20%"} theme="light">
							<Menu theme="light" mode="inline" selectedKeys={[selected]}>
								<Menu.Item
									key="columns-preferences"
									onClick={() => openLink("/settings/columns-preferences")}>
									Column Preferences
								</Menu.Item>
								<Menu.Item key="data-upload" onClick={() => openLink("/settings/data-upload")}>
									Data Upload
								</Menu.Item>
								<Menu.Item key="guide-upload" onClick={() => openLink("/upload")}>
									Guide Upload
								</Menu.Item>
								<Menu.Item key="user-management" onClick={() => openLink(USER_MANAGEMENT_LINK)}>
									User Management
								</Menu.Item>
								<Menu.Item key="reporting" onClick={() => openLink("/settings/reporting")}>
									Reporting
								</Menu.Item>
							</Menu>
						</Layout.Sider>

						<Layout.Content style={{width: "75%"}}>{this.props.children}</Layout.Content>
					</Layout>
				</Layout>
			</Layout>
		);
	}
}
