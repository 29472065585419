import React from "react";

import {AntdTable} from "../../components/custom/AntdTable";
import {WhereUsed} from "../../variants";

export function WhereUsedTable({equipment}) {
	return (
		<AntdTable
			tableSize={"small"}
			scroll={300}
			data={WhereUsed.getRows(equipment)}
			columns={WhereUsed.getColumns(equipment)}
		/>
	);
}
