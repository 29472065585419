import React, {Component} from "react";

import {Menu as ANTDMenu} from "antd";
import {Dropdown, Popover, Select} from "antd";
import FuzzySet from "fuzzyset.js";
import * as _ from "lodash";
import {Image, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import Button from "react-bootstrap-button-loader";
import Notifications, {notify} from "react-notify-toast";

import api from "./api";
import Auth from "./Auth";
import Menu from "./Menu.js";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import "./index.css";
import "antd/dist/antd.css";
import "./App.css";

const FileDownload = require("js-file-download");

function SearchComponent({sendRequest, searchInProgress, showLoad, query, setState}) {
	const visibility = searchInProgress || showLoad ? "visible" : "hidden";

	return (
		<div
			className={"search-row"}
			style={{
				minWidth: 450,
				marginTop: 10,
				marginLeft: "auto",
				display: "inline",
			}}>
			<form onSubmit={sendRequest} autoComplete="off">
				<input
					className="col-md-8 test-search"
					name="search"
					style={{height: 30}}
					autoComplete="off"
					value={query}
					placeholder="To submit query press Enter or Search button..."
					onChange={event => {
						setState({query: event.target.value});
					}}
				/>
				<Button
					id="search-button"
					className="search-button col-md-1"
					style={{height: 30, marginLeft: 5, width: 80}}
					onClick={sendRequest}>
					Search
				</Button>
			</form>
			<span className="col-md-1" style={{marginTop: -20, marginLeft: 5, width: 60}}>
				<Image style={{visibility}} src="/images/search-loader.gif" />
			</span>
		</div>
	);
}

class SearchBar extends Component {
	constructor(props) {
		super(props);
	}

	checkOperators(values) {
		return this.checkSelection(values);
	}

	handleChange(event) {
		this.props.setState({showAll: event.target.checked});
	}

	handleEquipmentChange(event) {
		this.props.setState({equipment: event.target.checked});
	}

	handleSelection(values) {
		this.props.setState({operator: this.checkOperators(values)});
	}

	checkSelection(selectedOperators) {
		if (selectedOperators.indexOf("all") > -1) {
			const fullList = [];
			for (const hub of this.props.state.operators) {
				for (const operator of hub.operators) {
					fullList.push(operator);
				}
			}
			return fullList;
		} else if (selectedOperators.indexOf("none") > -1) {
			return [];
		}
		return selectedOperators;
	}

	createSelectItems() {
		let items = [];
		if (this.props.state.operators) {
			items = [
				{label: "Select all", value: "all", key: 0},
				{label: "Deselect all", value: "none", key: 1},
			];
			for (let i = 0; i < this.props.state.operators.length; i++) {
				const hub = this.props.state.operators[i];
				const optGroup = {
					key: hub.name,
					label: hub.name,
					options: [],
				};
				if (hub.operators) {
					for (let j = 0; j < hub.operators.length; j++) {
						optGroup.options.push({
							key: hub.operators[j],
							value: hub.operators[j],
							label: `${hub.operators[j]}`,
						});
					}
				}
				items.push(optGroup);
			}
		}
		return items;
	}

	tagRender = props => {
		const {label, onClose} = props;
		const onPreventMouseDown = event => {
			event.preventDefault();
			event.stopPropagation();
		};

		return (
			<div
				onMouseDown={onPreventMouseDown}
				onClick={onClose}
				style={{
					marginRight: 3,
				}}>
				<span style={{color: "black", padding: 3}}>{label}</span>
			</div>
		);
	};

	render() {
		return (
			<div>
				<Notifications />
				<div className="center">
					<label htmlFor="searchAllCB" className="lbl">
						Include out of stock:{" "}
					</label>
					<input
						id="searchAllCB"
						type="checkbox"
						checked={this.props.state.showAll}
						onChange={event => this.handleChange(event)}
					/>
					<label htmlFor="showLibCB" className="lbl">
						Include equipment:{" "}
					</label>
					<input
						id="showLibCB"
						type="checkbox"
						checked={this.props.state.equipment}
						onChange={event => this.handleEquipmentChange(event)}
					/>
					<label className="lbl" htmlFor="operatorCB">
						Choose operator:
					</label>
					<Select
						multiple
						name="operators"
						style={{width: 200, maxWidth: 200}}
						maxTagCount={1}
						showArrow={true}
						maxTagTextLength={15}
						tagRender={this.tagRender}
						maxTagPlaceholder={props => (
							<Popover content={props.map(v => v.label).join(", ")}>
								<div> + {props.length}</div>
							</Popover>
						)}
						value={this.props.state.operator}
						mode={"multiple"}
						onChange={values => this.handleSelection(values)}
						options={this.createSelectItems()}></Select>
				</div>
			</div>
		);
	}
}

class Info extends Component {
	render() {
		if (this.props.info) {
			const info = this.props.info;
			return (
				<div className="App-info text-center">
					<span>
						<strong>Inventory Version:</strong> {info.version}
					</span>
					<span>
						<strong>Items:</strong> {info.items.toLocaleString()}
					</span>
					<span>
						<strong>Attributes:</strong> {info.attrs.toLocaleString()}
					</span>
					<span>
						<strong>Updated:</strong> {info.updated}
					</span>
				</div>
			);
		} else {
			return null;
		}
	}
}

class Attribute extends Component {
	render() {
		const attr = this.props.attr;
		let value = attr.value;
		if ("substring" in attr) {
			const from = attr.substring;
			const length = attr.matched.value.length;
			value =
				value.substr(0, from) +
				"<strong>" +
				value.substr(from, length) +
				"</strong>" +
				value.substr(from + length);
		}
		if (attr.match === "none") {
			return (
				<span
					className="Attr"
					dangerouslySetInnerHTML={{__html: value}}
					onClick={() => this.props.onRequestAttr({text: value, hash: new Date()})}
				/>
			);
		} else {
			const attrClass = "Attr Attr-matched Attr-" + attr.match;
			return (
				<OverlayTrigger
					overlay={
						<Tooltip id={attr.matched.value}>
							{attr.score.toFixed(2)}: {attr.matched.value}
						</Tooltip>
					}
					placement="top"
					delayShow={300}
					delayHide={150}>
					<span
						className={attrClass}
						dangerouslySetInnerHTML={{__html: value}}
						onClick={() => this.props.onRequestAttr({text: attr.value, hash: new Date()})}
					/>
				</OverlayTrigger>
			);
		}
	}
}

class ItemRow extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			wasOpen: false,
			connectedRows: null,
			hasRendered: false,
			showStock: false,
			image: "",
		};
		this.index = React.createRef();
		this.score = React.createRef();
		this.source = React.createRef();
		this.material = React.createRef();
		this.descr = React.createRef();
		this.manufacturer = React.createRef();
		this.part_number = React.createRef();
		this.stock = React.createRef();
		this.show = React.createRef();
		this.img = React.createRef();
		this.class = React.createRef();
		this.addInfo = React.createRef();
	}

	setWidth() {
		this.setState(state => ({
			open: state.wasOpen,
			showStock: state.wasStock,
			index: this.index.current && this.index.current.offsetWidth,
			score: this.score.current && this.score.current.offsetWidth,
			source: (this.source.current && this.source.current.offsetWidth) || 0,
			descr: this.descr.current && this.descr.current.offsetWidth,
			material: this.material.current && this.material.current.offsetWidth,
			manufacturer: this.manufacturer.current && this.manufacturer.current.offsetWidth,
			part_number: this.part_number.current && this.part_number.current.offsetWidth,
			addInfo: this.addInfo.current && this.addInfo.current.offsetWidth,
			stock: this.stock.current && this.stock.current.offsetWidth,
			show: this.show.current && this.show.current.offsetWidth,
			img: this.img.current && this.img.current.offsetWidth,
			class: this.class.current && this.class.current.offsetWidth,
		}));
	}

	componentDidMount() {
		this.setWidth();
		this.setState({
			matchedRows: this.props.item.matched,
		});
		if (this.props.item.has_image) {
			this.getImage();
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.manipulateAll !== this.props.manipulateAll) {
			this.handleShow(this.props.manipulateAll.value);
		}
		if (prevProps.showLib !== this.props.showLib || this.props.windowWidth !== prevProps.windowWidth) {
			this.setState({
				open: false,
				wasOpen: this.state.open,
				showStock: false,
				wasStock: this.state.showStock,
				hasRendered: !this.state.hasRendered,
				index: 0,
				score: 0,
				source: 0,
				descr: 0,
				// location: 0,
				material: 0,
				manufacturer: 0,
				part_number: 0,
				// surplus_level: 0,
				addInfo: 0,
				stock: 0,
				show: 0,
				img: 0,
				class: 0,
			});
		} else if (prevState.hasRendered !== this.state.hasRendered) {
			this.setWidth();
		}
	}

	checkRows(row) {
		return row.location.toUpperCase().trim() === this.props.item.location.toUpperCase().trim();
	}

	sendRequest() {
		api.get(
			`/search/report?operator=${this.props.item.operator}&material=${this.props.item.material}&showAll=${this.props.showAll}`,
		)
			.then(json => {
				this.setState({matchedRows: json.data.matched});
				if (!json.data || json.data.matched.length === 0) {
					notify.show("No matches found!", "warning", 1500);
				} else if (json.data.matched.length === 1) {
					notify.show(
						'All matches are out of stock! Please select "Include out of stock" to see them!',
						"warning",
						1500,
					);
				} else {
					this.setState({open: true});
				}
			})
			.catch(error => {
				console.log(error);
				this.setState({matchedRows: null});
			});
	}

	getImage() {
		const config = Auth.headers();
		config["responseType"] = "blob";

		const url =
			`/image?operator=${encodeURIComponent(this.props.item.operator)}&` +
			`material=${encodeURIComponent(this.props.item.material)}&` +
			`part_number=${encodeURIComponent(this.props.item.part_number)}`;

		api.get(url, config)
			.then(response => {
				const matrixBlob = new Blob([response.data], {type: "image/png"});
				const fileReader = new FileReader();
				fileReader.readAsDataURL(matrixBlob);
				fileReader.onload = () => {
					const result = fileReader.result;
					this.setState({image: result});
				};
			})
			.catch(error => console.log(error));
	}

	getDataLink(operator, material, url) {
		return (
			<a
				target="_blank"
				rel="noopener noreferrer"
				onClick={() => {
					sessionStorage.setItem("item_url", url);
					localStorage.setItem("item_url", url);
				}}
				href={`/data?operator=${encodeURIComponent(operator)}&material=${material}`}>
				<Image src="/images/menu-full.png" />
			</a>
		);
	}

	handleShow(shouldChange) {
		if (shouldChange !== undefined) {
			if (shouldChange !== this.state.open) {
				this.setState({open: shouldChange});
			}
		} else {
			this.setState({showStock: false});
			if (!this.state.open && !this.state.matchedRows) {
				this.sendRequest();
			} else {
				if (!this.state.matchedRows || this.state.matchedRows.length === 0) {
					notify.show("No matches found!", "warning", 1500);
				} else if (this.state.matchedRows.length === 1) {
					notify.show(
						'All matches are out of stock! Please select "Include out of stock" to see them!',
						"warning",
						1500,
					);
				} else {
					this.setState({
						open: !this.state.open,
					});
				}
			}
		}
	}

	substr(description, part_number) {
		let value = description;
		const from = value.indexOf(part_number.trim());
		const length = part_number.trim().length;
		if (from > -1) {
			value =
				value.substr(0, from) +
				"<strong>" +
				value.substr(from, length) +
				"</strong>" +
				value.substr(from + length);
		}

		return value;
	}

	generatorMatched(item) {
		const f = FuzzySet(item.description.split(","));
		const pn_original = f.get(item.part_number_original.trim())
			? f.get(item.part_number_original.trim())[0][1]
			: "";
		const pn_matched = f.get(item.part_number_matched.trim()) ? f.get(item.part_number_matched.trim())[0][1] : "";
		let descr = this.substr(item.description, pn_original);
		const showColumns = this.props.shouldShow;
		descr = this.substr(descr, pn_matched);
		item.url = this.props.item.url;
		return (
			<tr style={{backgroundColor: "#fff6e8"}}>
				<td className="text-center" colSpan="1" />
				{showColumns.includes("source") && <td className="text-center">{this.props.item.source}</td>}
				{showColumns.includes("material") && (
					<td>
						<span className="Item-material">
							{item.operator} {item.material}
						</span>
					</td>
				)}
				{showColumns.includes("cl_name") && (
					<td style={{wordBreak: "break-all"}} className="text-center">
						{item.cl_name}
					</td>
				)}
				{showColumns.includes("attrs") && (
					<td style={{wordBreak: "break-all"}}>
						<span dangerouslySetInnerHTML={{__html: descr}}></span>
					</td>
				)}
				{showColumns.includes("part_number") && (
					<td className="Item-part-number">
						<a
							href={`https://www.google.com/search?q=${item.part_number_original}`}
							target="_blank"
							rel="noopener noreferrer">
							{item.part_number_original}
						</a>
					</td>
				)}
				{showColumns.includes("manufacturer") && <td />}
				{showColumns.includes("stock_level") && (
					<td className="text-center">
						<a onClick={() => (this.props.onlyView ? null : this.props.onRequestItem(item, true))}>
							{item.stock_level} {item.unit}
						</a>
					</td>
				)}
				{showColumns.includes("score") && <td className="Item-part-number" colSpan="1" />}
				<td>{this.getDataLink(item.operator, item.material, item.url)}</td>
				{showColumns.includes("commonality") && <td />}
				{showColumns.includes("image") && <td />}
			</tr>
		);
	}

	generatorConnected(item, class_library, attrs) {
		item.raw = this.props.item.raw;
		item.url = this.props.item.url;
		return (
			<tr style={{backgroundColor: "#fff6e8"}}>
				<td className="text-center" colSpan="1" />
				<td className="text-center">{this.props.item.source}</td>
				<td>
					<span className="Item-material">
						{item.operator} {item.material}
					</span>
				</td>
				{this.props.showLib && <td className="text-center">{class_library}</td>}
				<td>{attrs}</td>
				<td className="Item-part-number">
					<a
						href={`https://www.google.com/search?q=${item.part_number}`}
						target="_blank"
						rel="noopener noreferrer">
						{item.part_number}
					</a>
				</td>
				<td className="text-center">
					<a onClick={() => (this.props.onlyView ? null : this.props.onRequestItem(item))}>
						{item.stock_level} {item.unit}
					</a>
				</td>
				<td className="Item-part-number" colSpan="1" />
				<td>{this.getDataLink(item.operator, item.material, item.url)}</td>
				<td colSpan="2" />
			</tr>
		);
	}

	render() {
		const item = this.props.item;
		const attrs = item.attributes.map((v, i) => (
			<Attribute key={i} attr={v} onRequestAttr={this.props.onRequestAttr} />
		));

		const class_library =
			item.cl_name !== "NA" ? (
				<OverlayTrigger
					overlay={
						<Tooltip id={item.cl_name}>
							{item.cl_iso} {item.cl_ref} {item.cl_type}
						</Tooltip>
					}
					placement="top"
					delayShow={300}
					delayHide={150}>
					<span className="Item-material">{item.cl_name}</span>
				</OverlayTrigger>
			) : (
				<span>{item.cl_name}</span>
			);

		const open_icon = this.state.open ? (
			<div>
				<Image src="/images/up-blue.png" />
				<a>{this.props.item.matched.length - 1}</a>
			</div>
		) : (
			<div>
				<Image src="/images/down-blue.png" />
				<a> {this.props.item.matched.length - 1}</a>
			</div>
		);

		const shouldShow = this.props.shouldShow;

		const rows = [];
		rows.push(
			<tr style={{backgroundColor: item.type === "equipment" ? "#fafff6" : ""}}>
				<td className="text-center" ref={this.index} style={{width: this.state.index ? this.state.index : 0}}>
					{this.props.index}
				</td>
				{shouldShow.includes("source") && (
					<td
						className="text-center"
						ref={this.source}
						style={{width: this.state.source ? this.state.source : 0}}>
						{item.source}
					</td>
				)}
				{shouldShow.includes("material") && (
					<td
						ref={this.material}
						className="text-center"
						style={{
							width: this.state.material ? this.state.material : 0,
							paddingLeft: 0,
							paddingRight: 0,
						}}>
						<OverlayTrigger
							overlay={
								<Tooltip id={item.material}>
									<div style={{width: 350}}>
										{this.props.showShortDescr
											? item.short_description
											: item.raw.split(",").join(", ")}
									</div>
								</Tooltip>
							}
							placement="top"
							delayShow={300}
							delayHide={150}>
							<span className="Item-material">
								{item.operator}
								<br /> {item.material}
							</span>
						</OverlayTrigger>
					</td>
				)}
				{shouldShow.includes("cl_name") && (
					<td
						className="text-center"
						ref={this.class}
						style={{
							wordBreak: "break-all",
							width: this.state.class ? this.state.class : 0,
						}}>
						{class_library}
					</td>
				)}
				{shouldShow.includes("attrs") && (
					<td
						ref={this.descr}
						style={{
							width: this.state.descr ? this.state.descr : 0,
							minWidth: 600,
						}}>
						{attrs}
					</td>
				)}
				{shouldShow.includes("part_number") && (
					<td
						className="Item-part-number"
						ref={this.part_number}
						style={{
							width: this.state.part_number ? this.state.part_number : 0,
							minWidth: 70,
						}}>
						<a
							href={`https://www.google.com/search?q=${item.part_number}`}
							target="_blank"
							rel="noopener noreferrer">
							{item.part_number}
						</a>
					</td>
				)}
				{shouldShow.includes("manufacturer") && (
					<td className="text-center" ref={this.manufacturer}>
						{item.manufacturer}
					</td>
				)}
				{shouldShow.includes("stock_level") && (
					<td
						className="text-center"
						ref={this.stock}
						style={{width: this.state.stock ? this.state.stock : 0}}>
						<button
							className="stock-level"
							onClick={() => (this.props.onlyView ? null : this.props.onRequestItem(this.props.item))}>
							{item.stock_level} {item.unit}{" "}
						</button>
					</td>
				)}
				{shouldShow.includes("score") && (
					<td
						className="text-center"
						ref={this.score}
						style={{width: this.state.score ? this.state.score : 0}}>
						{item.score.toFixed(2)}
					</td>
				)}
				<td
					className="text-center"
					ref={this.addInfo}
					style={{width: this.state.addInfo ? this.state.addInfo : 0}}>
					{this.getDataLink(item.operator, item.material, item.url)}
				</td>
				{shouldShow.includes("commonality") && (
					<td
						className="text-center"
						ref={this.show}
						style={{width: this.state.show ? this.state.show : 0, padding: 0}}
						onClick={() => this.props.showCommonality && this.handleShow()}>
						{this.props.item.matched.length > 1 ? open_icon : null}
					</td>
				)}
				{shouldShow.includes("image") && (
					<td
						className="text-center"
						ref={this.img}
						style={{
							width: this.state.img ? this.state.img : 0,
							minWidth: 30,
							maxWidth: 30,
							padding: 0,
						}}>
						<img style={{width: "100%"}} src={this.state.image} />
					</td>
				)}
			</tr>,
		);
		if (this.state.matchedRows && this.state.open) {
			for (const element of this.state.matchedRows) {
				if (!(String(item.material) === element.material && item.operator === element.operator)) {
					rows.push(this.generatorMatched(element));
				}
			}
		} else if (this.state.connectedRows && this.state.showStock) {
			for (const element of this.state.connectedRows) {
				rows.push(this.generatorConnected(element, class_library, attrs));
			}
		}

		if (
			(this.state.open || this.state.showStock) &&
			((this.state.matchedRows && this.state.matchedRows.length > 1) || (this.state.connectedRows && item.show))
		) {
			return (
				<tr>
					<td colSpan={shouldShow.length + 1} style={{padding: 0}}>
						<Table bordered condensed hover style={{margin: 0}}>
							<tbody style={{backgroundColor: "#fdfff0"}}>{rows}</tbody>
						</Table>
					</td>
				</tr>
			);
		} else {
			return rows[0];
		}
	}
}

class ItemTable extends Component {
	getListOfColumns = () => {
		api.get("/settings/table-default?name=Search Table").then(json =>
			this.setState({columns: json.data["columns"]}),
		);

		api.get("/settings/table-overriden?name=Search Table")
			.then(json => this.setState({overridden: json.data["columns"]}))
			.catch(error => this.setState({overridden: []}));

		api.get("/settings/table-overriden?name=Description")
			.then(json =>
				this.setState({
					showShortDescr: json.data["columns"].length === 1 && json.data["columns"][0] === "short",
				}),
			)
			.catch(error => this.setState({showShortDescr: false}));
	};

	state = {
		columns: [],
		overridden: [],
		showShortDescr: false,
	};

	resize = () => this.forceUpdate();

	componentDidMount() {
		this.getListOfColumns();
		window.addEventListener("resize", this.resize);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.resize);
	}

	handleDownload() {
		notify.show("Start downloading of results", "info", 5000);
		this.props.handleDownload();
	}

	handleManipulation(task) {
		if (task === "Expand All") {
			this.props.handleLoading(true, {value: true, date: new Date()});
		} else {
			this.props.handleLoading(true, {value: false, date: new Date()});
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.shouldSet !== this.props.shouldSet) {
			this.props.handleLoading(false, null);
		}
	}

	render() {
		const shouldShow = this.state.overridden.length ? this.state.overridden : this.state.columns;

		const rows = this.props.values.map((v, i) => (
			<ItemRow
				key={v.operator + "/" + v.material}
				index={i + 1}
				item={v}
				showShortDescr={this.state.showShortDescr}
				shouldShow={shouldShow}
				showCommonality={this.props.showCommonality}
				manipulateAll={this.props.shouldSet}
				history={this.props.history}
				windowWidth={window.innerWidth}
				showLib={this.props.showLib}
				showAll={this.props.showAll}
				onRequestItem={this.props.onRequestItem}
				onRequestAttr={this.props.onRequestAttr}
				onlyView={this.props.onlyView}
			/>
		));

		const options = ["Expand All", "Collapse All"];

		if (rows.length > 0) {
			const dropdownmenu = (
				<ANTDMenu>
					<ANTDMenu.Item onClick={() => this.handleManipulation("Expand All")}>Expand All</ANTDMenu.Item>
					<ANTDMenu.Item onClick={() => this.handleManipulation("Collapse All")}>Collapse All</ANTDMenu.Item>
				</ANTDMenu>
			);

			return (
				<div>
					<Notifications />
					<p>
						<strong>Search results for: </strong>
						{this.props.search}{" "}
						<a style={{float: "right"}} onClick={() => this.handleDownload()}>
							Download search results
						</a>
					</p>
					<Table striped bordered condensed hover className="Items">
						<thead>
							<tr>
								<th>#</th>
								{shouldShow.includes("source") && <th>Source</th>}
								{shouldShow.includes("material") && <th>Material</th>}
								{shouldShow.includes("cl_name") && <th>Material class</th>}
								{shouldShow.includes("attrs") && <th>Attributes</th>}
								{shouldShow.includes("part_number") && <th>Part Number</th>}
								{shouldShow.includes("manufacturer") && <th>Manufacturer</th>}
								{shouldShow.includes("stock_level") && <th>Stock Level</th>}
								{shouldShow.includes("score") && <th>Score</th>}
								{<th>Full data</th>}
								{shouldShow.includes("commonality") && (
									<th>
										{this.props.showCommonality && (
											<Dropdown overlay={dropdownmenu}>
												<Image src="/images/menu-horizontal.png" />
											</Dropdown>
										)}
									</th>
								)}

								{shouldShow.includes("image") && <th />}
							</tr>
						</thead>
						<tbody>{rows}</tbody>
					</Table>
				</div>
			);
		} else {
			return null;
		}
	}
}

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			results: [],
			selectedItem: null,
			selectedAttr: null,
			showContactForm: false,
			searchCount: 0,
			showLoad: false,
			isDisabled: !Auth.isAuthenticated(),
			onlyView: true,
			showLib: false,
			showCommonality: true,
			showEdit: false,
			top: 50,
			operator: [],
			showAll: true,
			equipment: true,
			inputTop: 50,
			search: "",
			start: false,
		};
	}

	componentDidMount() {
		api.get(`/validate_permission?permission=view`)
			.then(() => {
				this.setState({onlyView: true});
			})
			.catch(error => {
				console.log(error.response.data);
				this.setState({onlyView: false});
			});
		api.get(`/validate_permission?permission=internal`)
			.then(() => {
				this.setState({showCommonality: false});
			})
			.catch(error => {
				console.log(error.response.data);
				this.setState({showCommonality: true});
			});
		api.get(`/info`)
			.then(json => this.setState({info: json.data}))
			.catch(error => console.log(error.response.data));
		api.get(`/operators?global=true`)
			.then(json =>
				this.setState({
					operators: json.data,
					operator: this.getDefaultOperators(json.data),
				}),
			)
			.catch(error => console.log(error));
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevState.temp !== this.state.temp) {
			setTimeout(() => {
				this.setState((state, props) => ({
					shouldSet: this.state.temp !== null ? this.state.temp : this.state.shouldSet,
				}));
			}, 1);
			this.forceUpdate();
		}
		if (prevState.top !== this.state.top) {
			this.executeSearch(this.state.search, this.state.showAll, this.state.equipment, this.state.operator, true);
		}
		if (this.state.operators && this.state.operators !== prevState.operators) {
			const query = new URLSearchParams(window.location.search).get("search");
			if (query) {
				this.setState({query: query, start: true});
			}
		}
		if (this.state.start !== prevState.start && this.state.start) {
			this.search();
			this.setState({start: false});
		}
	}

	createOperators(full, selected) {
		const result = {};
		for (const hub in full) {
			for (const operator in full[hub].operators) {
				if (selected.indexOf(full[hub].operators[operator]) > -1) {
					if (!(full[hub].name in result)) {
						result[full[hub].name] = [];
					}
					result[full[hub].name].push(full[hub].operators[operator]);
				}
			}
		}
		return {operators: result};
	}

	executeSearch(string, showAll, equipment, operator, fullOperators, resend = false) {
		if (
			string.length > 1 &&
			(this.state.lastSearch !== string ||
				this.state.lastShowAll !== showAll ||
				this.state.lastEquipment !== equipment ||
				this.state.lastOperator !== operator ||
				resend)
		) {
			this.setState({
				searchCount: this.state.searchCount + 1,
				loading: true,
				results: [],
			});
			api.post(
				`/search?top=${this.state.top}&query=${encodeURIComponent(string)}&showAll=${showAll}&equipment=${equipment}`,
				this.createOperators(fullOperators, operator),
			)
				.then(json =>
					this.setState({
						results: json.data.results,
						lastSearch: string,
						lastShowAll: showAll,
						lastEquipment: equipment,
						lastOperator: operator,
					}),
				)
				.catch(error => {
					console.log(error.response.data);
				})
				.finally(() => this.setState({searchCount: this.state.searchCount - 1}));
		}
	}

	getKeys(item) {
		return {
			operator: item.operator,
			material: item.material,
			score: item.score.toFixed(2),
		};
	}

	handleDownLoad() {
		const items = this.state.results;
		this.setState({triedToSubmit: true});

		const config = Auth.headers();
		config["responseType"] = "blob";

		api.post(`/search/download?search=${this.state.search}`, items, config)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1];
				FileDownload(response.data, filename, filename);
			})
			.catch(error => alert(error))
			.finally(() =>
				this.setState({
					triedToSubmit: false,
					submitInProgress: false,
				}),
			);
	}

	handleChange() {
		this.setState({
			showLib: !this.state.showLib,
		});
	}

	selectItem(item, isCommonality) {
		this.setState({selectedItem: item, isCommonality: isCommonality});
	}

	selectAttr(attr) {
		this.setState({selectedAttr: attr});
	}

	handleInput(event) {
		const value = event.target.value;
		const input = value !== "" ? parseInt(event.target.value) : value;
		this.setState({inputTop: isNaN(input) ? this.state.inputTop : input});
	}

	handleEdit() {
		if (this.state.searchCount > 0) {
			notify.show("Previous query is still executing. Please wait till the results show up", "error", 5000);
			this.setState({
				showEdit: !this.state.showEdit,
				inputTop: this.state.top,
			});
		} else {
			this.setState({
				top: this.state.inputTop === "" || this.state.inputTop < 1 ? this.state.top : this.state.inputTop,
				showEdit: !this.state.showEdit,
			});
			if (this.state.inputTop === "") {
				this.setState({
					inputTop: this.state.top,
				});
			}
		}
	}

	getDefaultOperators(data) {
		const operators = this.createItems(data[0]).slice();

		for (const hub in data) {
			if (data[hub].name.toLowerCase().indexOf("supplier") > -1) {
				for (const operator in data[hub].operators) {
					if (operators.indexOf(data[hub].operators[operator]) === -1) {
						operators.push(data[hub].operators[operator]);
					}
				}
			}
		}

		return operators;
	}

	createItems = hub => hub.operators;

	search = event => {
		event && event.preventDefault();
		this.executeSearch(
			this.state.query,
			this.state.showAll,
			this.state.equipment,
			this.state.operator,
			this.state.operators,
		);
	};

	render() {
		const onAttrClick = attr => {
			let fullString = this.state.query;
			if (fullString.includes(attr.text)) {
				fullString = _.replace(fullString, attr.text, "");
			} else {
				fullString = fullString + " " + attr.text;
			}
			fullString = _.trim(fullString, " ");
			this.setState({query: fullString});
		};

		const edit = this.state.showEdit ? (
			<div className="text-center">
				Number of shown results:
				<input
					style={{marginRight: 5, marginLeft: 5, width: 50}}
					maxLength={3}
					value={this.state.inputTop}
					onChange={event => this.handleInput(event)}
				/>
				<button onClick={e => this.handleEdit()}>Submit</button>
			</div>
		) : (
			<div className="text-center">
				<p>
					Number of shown results:{" "}
					<a onClick={() => this.setState({showEdit: !this.state.showEdit})}>{this.state.top}</a>
				</p>
			</div>
		);

		return (
			<div>
				<Menu
					history={this.props.history}
					search={
						<SearchComponent
							searchInProgress={this.state.searchCount > 0}
							query={this.state.query}
							setState={state => this.setState(state)}
							sendRequest={this.search}
							showLoad={this.state.showLoad}
						/>
					}
				/>
				<div className="App-root center-block">
					<Notifications />
					<SearchBar
						attr={this.state.selectedAttr}
						state={this.state}
						createItems={this.createItems}
						setState={state => this.setState(state)}
						showLoad={this.state.showLoad}
						info={this.state.info}
						onCheckBoxChange={() => this.handleChange()}
					/>
					<Info info={this.state.info} />
					<ItemTable
						showLib={this.state.showLib}
						onlyView={this.state.onlyView}
						showCommonality={this.state.showCommonality}
						showAll={this.state.showAll}
						handleLoading={(startLoading, shouldSet) =>
							this.setState({
								showLoad: startLoading,
								temp: shouldSet,
							})
						}
						shouldSet={this.state.shouldSet}
						handleDownload={() => this.handleDownLoad()}
						search={this.state.lastSearch}
						values={this.state.results}
						onRequestItem={(item, isCommonality) => this.selectItem(item, isCommonality)}
						onRequestAttr={onAttrClick}
					/>
					<div>{edit}</div>
				</div>
			</div>
		);
	}
}

export default App;
