import React from "react";

import {SettingOutlined} from "@ant-design/icons";
import {
	About,
	ContactUsLink,
	ContactUsModal,
	CurrentUserAvatar,
	GuideButton,
	HomeLink,
	LogOut,
	OtherResources,
} from "@digatex/digatex-ui-lib";
import {Layout, Row, Space} from "antd";
import {Col} from "antd/es";
import {Image} from "react-bootstrap";

import api from "./api";
import {CurrentUserContext} from "./contexts/CurrentUserContext";

import "./Menu.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import "./index.css";

const sendEmail = formData => api.post("/email/contact", formData);

export class Menu extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showContactForm: false,
			triedToSubmit: false,
			submitInProgress: false,
		};

		this.formRef = React.createRef();
	}

	render() {
		const title =
			window.location.href.includes("admin") ||
			window.location.href.includes("users") ||
			window.location.href.includes("dashboard")
				? "Download quick reference guide for administrators"
				: "Download quick reference guide";

		return (
			<Row>
				<Col span={24}>
					<Layout.Header style={{padding: 10, display: "flex", alignItems: "center"}}>
						<HomeLink />
						<a href="/settings">
							<SettingOutlined
								style={{
									color: "white",
									fontSize: "32px",
									marginTop: "20px",
									verticalAlign: "center",
									paddingLeft: 10,
								}}
							/>
						</a>
						<div
							style={{
								display: "inline",
								paddingTop: -5,
								paddingLeft: 5,
							}}>
							<a href="/search">
								<Image src={`/images/magnify-white.png`} />
							</a>
						</div>
						{this.props.search}
						<div style={{marginLeft: "auto"}}>
							{
								<Row>
									<Space size={"middle"}>
										<About />
										<OtherResources />
										<GuideButton module="inventory" />
										<ContactUsLink show={() => this.setState({showContactForm: true})} />
										<LogOut />
										<CurrentUserAvatar />
									</Space>
								</Row>
							}
						</div>
					</Layout.Header>
					<ContactUsModal
						show={this.state.showContactForm}
						sendEmail={sendEmail}
						close={() => this.setState({showContactForm: false})}
					/>
				</Col>
			</Row>
		);
	}
}

Menu.contextType = CurrentUserContext;

export default Menu;
