import React, {useState} from "react";

import {UploadOutlined} from "@ant-design/icons";
import {Auth} from "@digatex/digatex-ui-lib";
import {Button, message, Upload} from "antd";

import api from "../../api";
import {AntdTable} from "../../components/custom/AntdTable";
import {maxFileSizeMB, properties} from "../../properties";

export function AttachedDocuments({item, documentsList, updateDocsTable}) {
	const [fileList, setFileList] = useState([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState([]);
	const [loading, setLoading] = useState(false);

	const getMBValue = bytes => {
		const kb = bytes / 1024;
		return kb / 1024;
	};

	const handleChange = info => {
		if (info.file.status === "done") {
			message.success("The file was successfully uploaded");
		}

		if (info.file.status === "error") {
			message.error(info.file.response);
		}

		let newFileList = [info.file];

		newFileList = newFileList.map(file => {
			if (file.response) {
				file.url = file.response.url;
			}
			return file;
		});
		setFileList(newFileList);
		updateDocsTable(item.operator, item.material);
	};

	const props = {
		action: `${properties.apiUrl}/api/documents/save?operator=${item.operator}&material=${item.material}`,
		headers: Auth.headers(),
		showUploadList: false,
		name: fileList.length === 1 && fileList[0].name,
		onChange: handleChange,
		beforeUpload: file => {
			if (getMBValue(file.size) > maxFileSizeMB) {
				message.error(
					`${file.name} is too big! Please do not attach documents greater than ${maxFileSizeMB}MB`,
				);
				return Upload.LIST_IGNORE;
			}
			const allowedTypes = [
				"application/pdf",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
			];
			if (allowedTypes.includes(file.type)) {
				message.info("Start uploading the document");
				return true;
			}
			message.error(`${file.name} has incorrect type! Please attach pdf,xlsx or docx files!`);
			return Upload.LIST_IGNORE;
		},
	};

	const openFile = _id => {
		const url = `/document/get`;

		const config = Auth.headers();
		config["responseType"] = "blob";

		api.post(url, {_id}, config)
			.then(response => {
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1].replace(/"/g, "");
				const file = response.data;
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(file);
				} else {
					var fileURL = URL.createObjectURL(file);
					window.open(fileURL, "_blank");
				}
			})
			.catch(error => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
				} else {
					alert(error);
				}
			});
	};

	const columns = [
		{
			key: "filename",
			title: "File Name",
			dataIndex: "filename",
		},
		{
			key: "user",
			title: "User",
			dataIndex: "user",
		},
		{
			key: "actions",
			title: "Actions",
			dataIndex: "_id",
			render: _id => (
				<div className={"beautiful-link"} onClick={() => openFile(_id)}>
					Open
				</div>
			),
		},
	];

	const onSelectChange = newSelectedRowKeys => {
		setSelectedRowKeys(newSelectedRowKeys);
	};
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};
	const hasSelected = selectedRowKeys.length > 0;

	const _delete = () => {
		setLoading(true);
		api.post("/documents/delete", selectedRowKeys)
			.then(json => {
				updateDocsTable(item.operator, item.material);
				setLoading(false);
			})
			.catch(error => setLoading(false));
	};

	const getDataSource = () => {
		const dataSource = [];
		for (const doc of documentsList) {
			dataSource.push({...doc, key: doc._id});
		}
		return dataSource;
	};

	return (
		<>
			<div>
				<div style={{float: "right", padding: 5}}>
					<Upload {...props} fileList={fileList}>
						<Button icon={<UploadOutlined />}>Click to Attach Documents</Button>
					</Upload>
				</div>
			</div>
			<Button type="primary" onClick={_delete} disabled={!hasSelected} loading={loading}>
				Delete
			</Button>
			<AntdTable
				rowSelection={rowSelection}
				tableSize={"small"}
				scroll={300}
				data={getDataSource()}
				columns={columns}
			/>
		</>
	);
}
