import * as jose from "jose";
import Keycloak from "keycloak-js";

import {KEYCLOAK_AUTH_SERVER_URL} from "./properties";

const _kc = new Keycloak({
	url: KEYCLOAK_AUTH_SERVER_URL,
	realm: "SIMS",
	clientId: "inventory-ui",
});

class Auth {
	init(onAuthenticatedCallback) {
		_kc.init({
			onLoad: "login-required",
			pkceMethod: "S256",
		})
			.then(onAuthenticatedCallback)
			.catch(console.error);
	}

	login = _kc.login;

	logout = _kc.logout;

	isAuthenticated() {
		return !!_kc.token;
	}

	async getOtherToken() {
		const secret = new TextEncoder().encode("CJYNcmpqIken3YyIjarBUHeXrnY0CslP");

		const jwt = await new jose.SignJWT({sub: "dev@digatex.com"}).setProtectedHeader({alg: "HS256"}).sign(secret);

		return jwt;
	}

	headers() {
		return {Authorization: "Bearer " + _kc.token};
	}

	sendToOther = _callback => {
		this.getOtherToken().then(jwt => {
			const config = {headers: {Authorization: "Bearer " + jwt}};
			_callback(config);
		});
	};

	updateToken = successCallback => _kc.updateToken(5).then(successCallback).catch(this.login);
}

export default new Auth();
