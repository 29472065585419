const variant = process.env.REACT_APP_PRODUCT_VARIANT ?? "default";

async function load(moduleName) {
	const loadVariant = async variant =>
		import(`./${variant}/${moduleName}`).then(module => {
			console.log(`Loaded "${variant}" variant of ${moduleName}`);
			return module;
		});

	return loadVariant(variant)
		.catch(() => loadVariant("default"))
		.then(module => (typeof module.default == "object" ? module.default : new module.default()));
}

export let WhereUsed;
export let Transactions;

export async function loadVariants() {
	WhereUsed = await load("WhereUsed");
	Transactions = await load("Transactions");
}
