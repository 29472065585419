import React from "react";

import {EquipmentInfoTabs} from "./EquipmentInfoTabs";
import {HeaderInfo} from "./HeaderInfo";
import api from "../../api";
import {properties} from "../../properties";

export class EquipmentPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {},
			headerKeys: [],
			informationData: [],
			bomData: [],
			maint_works: [],
		};
	}

	getSummaryValue = (columns, item) => {
		const summary = [];
		for (const key of columns) {
			summary.push({
				key,
				value: item[key] || "",
			});
		}
		return summary;
	};

	getBOMData = (operator, material) => {
		if (this.state.bomData.length === 0) {
			api.get(`/get/equipment-materials?operator=${encodeURIComponent(operator)}&material=${material}`)
				.then(json => {
					this.setState({bomData: json.data["materials"] || []});
				})
				.catch(error => console.log(error));
		}
	};

	getMaintWorks(sort_field) {
		api.get(`/get/maint-works?sort=${sort_field}`)
			.then(json => {
				this.setState({maint_works: json.data["materials"]});
			})
			.catch(error => console.log(error));
	}

	componentDidMount() {
		const operator = new URLSearchParams(window.location.search).get("operator");
		const material = new URLSearchParams(window.location.search).get("material");

		let url = properties.apiUrl;
		if (sessionStorage.getItem("item_url") && sessionStorage.getItem("item_url") !== null) {
			url = sessionStorage.getItem("item_url");
		} else if (localStorage.getItem("item_url") && localStorage.getItem("item_url") !== null) {
			url = localStorage.getItem("item_url");
			sessionStorage.setItem("item_url", url);
		}

		api.post(`/item/full?material=${material}&operator=${encodeURIComponent(operator)}`, {url})
			.then(json => {
				this.setState({
					item: json.data.item[0],
				});

				const infColumns = ["components", "identical_boms", "pm_plans", "pm_executed", "cm_executed", "ratio"];

				this.setState({
					headerKeys: [
						"asset_class",
						"material",
						"floc",
						"description",
						"object_type",
						"manufacturer",
						"area",
						"sort_field",
						"part_number",
						"model_number",
						"cl_name",
						"construction_type",
						"criticality",
					],
					informationData: this.getSummaryValue(infColumns, json.data.item[0]),
				});

				this.props.onItemUpdate(json.data.item[0].part_number);

				this.getBOMData(operator, material);
				this.getMaintWorks(json.data.item[0][properties.plansMappingKey]);
			})
			.catch(() => {
				if (!properties.isDebug) {
					window.location.href = "/search?search=" + material;
				}
			});
	}

	render() {
		const item = this.state.item;
		if (item && !item.hasOwnProperty("raw")) item.raw = item.description;

		return (
			<div>
				<div className="catalog-root center-block">
					<HeaderInfo
						image={this.props.image}
						item={item}
						headerColumns={this.state.headerKeys}
						images={this.props.images}
						handleModalOpen={() => this.props.openImageModal()}
						showEdit={this.props.show}
					/>

					<EquipmentInfoTabs
						item={item}
						updateDocsTable={this.props.getDocumentsList}
						documentsList={this.props.documentsList}
						maint_works={this.state.maint_works}
						bomData={this.state.bomData}
					/>
				</div>
			</div>
		);
	}
}
