import React from "react";

import {AntdTable} from "../../components/custom/AntdTable";

export function CommonalityTable({item, commonality}) {
	const columns = [
		{
			key: "material",
			title: "Material",
			dataIndex: "material",
			render: (material, _item) => (
				<a
					href={`/data?operator=${_item.operator}&material=${_item.material}`}
					target="_blank"
					rel="noopener noreferrer">
					{_item.operator} {_item.material}
				</a>
			),
		},
		{
			key: "description",
			title: "Description",
			dataIndex: "description",
			width: "60%",
		},
		{
			key: "part_number_original",
			title: "Part Number",
			dataIndex: "part_number_original",
			render: part_number => (
				<a href={`https://www.google.com/search?q=${part_number}`} target="_blank" rel="noopener noreferrer">
					{part_number}
				</a>
			),
		},
		{
			key: "stock_level",
			title: "Stock Level",
			dataIndex: "stock_level",
			render: (stock, _item) => (
				<div>
					{stock} {_item.unit}
				</div>
			),
		},
	];

	return <AntdTable tableSize={"small"} scroll={500} data={commonality} columns={columns} />;
}
