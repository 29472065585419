import React, {useState} from "react";

import {TransactionsGraph} from "./TransactionsGraph";
import {AntdModal} from "../../components/custom/AntdModal";
import {AntdTable} from "../../components/custom/AntdTable";
import {Transactions} from "../../variants";

export function TransactionsTab({transactionsInfo}) {
	const [isModalVisible, setIsModalVisible] = useState(false);

	return (
		<>
			{transactionsInfo.graphData && transactionsInfo.graphData.length > 0 && (
				<div
					style={{float: "right", color: "#004987"}}
					className="beautiful-link"
					onClick={() => setIsModalVisible(true)}>
					Open graph
				</div>
			)}
			<AntdTable
				tableSize={"small"}
				scroll={300}
				data={transactionsInfo.tableData}
				columns={Transactions.getTransactionsTableColumns(transactionsInfo)}
			/>

			<AntdModal
				isModalVisible={isModalVisible}
				title={"Transaction Data"}
				onCancel={() => setIsModalVisible(false)}
				onOk={() => setIsModalVisible(false)}>
				<TransactionsGraph graphData={transactionsInfo.graphData} />
			</AntdModal>
		</>
	);
}
