import React from "react";

import {AntdTable} from "../../components/custom/AntdTable";

export function HersTable({hers}) {
	const columns = [
		{
			key: "description",
			title: "Part Number",
			dataIndex: "part_number",
			width: "60%",
		},
		{
			key: "stock_level",
			title: "Manufacturer",
			dataIndex: "manufacturer",
		},
	];

	return <AntdTable tableSize={"small"} scroll={500} data={hers} columns={columns} />;
}
