import React from "react";

import {Descriptions, Tag} from "antd";

import {beautifyKey} from "./HeaderInfo";

export function StructuredData({structured}) {
	const getLabel = field => {
		const mapping = {
			ratio: "PM:CM Ratio",
			cm_executed: "CM Work Executed",
			pm_executed: "PM Work Executed",
			pm_plans: "PM Maintenance Plans",
			identical_boms: "Identical BOMs",
		};
		return mapping[field] || beautifyKey(field);
	};

	const getStatus = status => {
		if (status === "annotated") {
			return <Tag color={"blue"}>ANNOTATED</Tag>;
		} else if (status === "finalAttribute") {
			return <Tag color={"green"}>VERIFIED</Tag>;
		}
	};

	return (
		<Descriptions size="small" bordered>
			{structured.map(row => (
				<Descriptions.Item span={3} labelStyle={{width: "20%"}} label={<b>{getLabel(row.key)}</b>}>
					{row.value} {getStatus(row.status)}
				</Descriptions.Item>
			))}
		</Descriptions>
	);
}
