import React from "react";

import {Line} from "react-chartjs-2";

import {ROP_MIN_LABEL} from "../../properties";

export function TransactionsGraph({graphData}) {
	const getTransactionsChartData = () => {
		const tr = [...graphData];

		return {
			labels: tr.map((v, i) => v["Posting Date"]),
			datasets: [
				{
					label: "SOH",
					data: tr.map((v, i) => v["stock"]),
					borderColor: "blue",
					backgroundColor: "rgba(0, 255, 128, 0)",
					fill: false,
					stepped: true,
				},
				{
					label: ROP_MIN_LABEL,
					data: tr.map((v, i) => v["rop"]),
					backgroundColor: "rgba(0, 255, 128, 0)",
					borderColor: "rgba(0, 255, 128, 0.2)",
				},
				{
					label: "MAX",
					data: tr.map((v, i) => v["max"]),
					fill: false,
					backgroundColor: "rgba(54, 162, 235,0)",
					borderColor: "rgba(255, 0, 0, 0.3)",
				},
			],
		};
	};

	const options = {
		interaction: {
			intersect: false,
			axis: "x",
		},
		scales: {
			y: {
				ticks: {
					min: 0,
					stepSize: 1,
				},
			},
		},
	};

	return <Line data={getTransactionsChartData()} options={options} />;
}
