import React from "react";
import {Route, Switch} from "react-router";

import Admin from "./Admin";
import App from "./App";
import {FullData} from "./FullData";
import GlobalSearchAdmin from "./globalSearch";
import {MenuLayout} from "./layouts/MenuLayout";
import {RouteWithLayout} from "./layouts/RouteWithLayout";
import {FullPage} from "./pages/additional-data/FullPage";
import ProposedBOMs from "./pages/additional-data/ProposedBOM";
import {ColumnsPreferences} from "./pages/settings/ColumnsPreferences";
import {Reporting} from "./pages/settings/Reporting";
import UploadComp from "./Upload";

export function IHRouter() {
	return (
		<div>
			<Switch>
				<Route path="/global-search" render={props => <GlobalSearchAdmin {...props} />} />
				<Route path="/proposed*" render={props => <ProposedBOMs {...props} />} />
				<Route path="/additional-info*" render={props => <FullData {...props} />} />

				<Route path="/data*" render={props => <FullPage {...props} />} />

				<Route path="/upload" render={props => <UploadComp {...props} />} />

				<RouteWithLayout
					layout={MenuLayout}
					path="/settings/columns-preferences"
					component={ColumnsPreferences}
				/>
				<RouteWithLayout layout={MenuLayout} path="/settings/reporting" component={Reporting} />
				<RouteWithLayout layout={MenuLayout} path="/settings/data-upload" component={Admin} />
				<RouteWithLayout layout={MenuLayout} path="/settings" />

				<Route path="/*" render={props => <App {...props} />} />
			</Switch>
		</div>
	);
}
