import React, {useEffect, useState} from "react";

import {Card, Checkbox, Col, Collapse, message, Row, Switch} from "antd";

import api from "../../api";
import {getLabel} from "../additional-data/labelMapper";

const {Panel} = Collapse;

export function ColumnsPreferences() {
	const [tables, setTables] = useState([]);
	const [defaultColumns, setDefaultColumns] = useState({});
	const [overriddenColumns, setOverriddenColumns] = useState({});
	const [activeKey, setActiveKey] = useState([]);

	const [switchState, setSwitchState] = useState(false);

	useEffect(() => {
		api.get("/settings/available-tables").then(json => setTables(json.data));
	}, []);

	const getDefaultColumns = tableName => {
		api.get(`/settings/table-default?name=${tableName}`)
			.then(json => setDefaultColumns(state => ({...state, [tableName]: json.data.columns})))
			.catch(err => console.log(err));
	};

	const getOverridenColumns = tableName => {
		api.get(`/settings/table-overriden?name=${tableName}`)
			.then(json => {
				setOverriddenColumns(state => ({...state, [tableName]: json.data.columns}));
				if (tableName === "Description") {
					setSwitchState(json.data.columns[0] === "short");
				}
			})
			.catch(error => setOverriddenColumns(state => ({...state, [tableName]: null})));
	};

	const getChecked = tableName => {
		if (overriddenColumns[tableName]) {
			return overriddenColumns[tableName];
		} else if (defaultColumns[tableName]) {
			return defaultColumns[tableName];
		}
	};

	useEffect(() => {
		for (const table of tables) {
			getDefaultColumns(table.name);
			getOverridenColumns(table.name);
		}
		getDefaultColumns("Description");
		getOverridenColumns("Description");
	}, [tables]);

	const onChange = (checked, tableName) => {
		api.post(`/settings/override-columns?name=${tableName}`, {
			columns: [...checked, "label"],
		}).then(json => {
			message.success("Updated");
			setOverriddenColumns({
				...overriddenColumns,
				[tableName]: json.data.columns,
			});
		});
	};

	const saveValue = state => {
		api.post(`/settings/override-columns?name=Description`, {
			columns: [state ? "short" : "long"],
		}).then(json => {
			message.success("Updated");
			setSwitchState(state);
		});
	};

	const keys = tables.map((v, i) => (i + 1).toString());

	return (
		<Row>
			<Col span={24} style={{padding: 10}}>
				<Card>
					Show on hover:
					<div style={{margin: "10px 0"}}>
						Full Description
						<Switch
							style={{margin: "0 10px"}}
							checked={switchState}
							onClick={() => {
								saveValue(!switchState);
							}}
						/>
						Short Description
					</div>
					<Collapse defaultActiveKey={activeKey} activeKey={activeKey} onChange={key => setActiveKey(key)}>
						{tables &&
							tables.map((v, i) => (
								<Panel key={(i + 1).toString()} header={v.name}>
									<Checkbox.Group
										value={getChecked(v.name)}
										style={{
											width: "100%",
										}}
										onChange={checked => onChange(checked, v.name)}>
										<Row>
											{defaultColumns[v.name] &&
												defaultColumns[v.name].map(col => {
													if (col !== "label") {
														return (
															<Col span={8}>
																<Checkbox value={col}>{getLabel(col)}</Checkbox>
															</Col>
														);
													} else return null;
												})}
										</Row>
									</Checkbox.Group>
								</Panel>
							))}
					</Collapse>
				</Card>
			</Col>
		</Row>
	);
}
