export const getFilterValue = (array = [], _key) => {
	const values = [...array].map(v => v[_key]);
	const unique = new Set(values);
	return [...unique].map(v => ({
		text: v,
		value: v,
	}));
};

export const getBoolFilterValue = (array = [], _key) => {
	const values = [...array].map(v => v[_key]);
	const unique = new Set(values);
	return [...unique].map(v => ({
		text: v ? "Yes" : "No",
		value: v,
	}));
};

export function sortByKeys(values, keys) {
	return [...values].sort((a, b) => keys.indexOf(a.key) - keys.indexOf(b.key));
}
